import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Emoji from 'react-emoji-render'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Octicon, { MarkGithub, Location, Mail } from '@githubprimer/octicons-react'
import useSiteMetadata from '../hooks/siteMetaData'
import { ThemeContext } from "../theme-context"
import SidebarData from '../data/sidebar.json'
import './mastHead.scss'

const TWITTER_ICON = 'https://cdn3.iconfinder.com/data/icons/transparent-on-dark-grey/500/icon-03-512.png'

const scrollDown = () => {
  if (window.innerWidth >= 768) {
    return
  }
  setTimeout(() => {
    const item = document.getElementById('child-box')
      if (item && item.scrollIntoView) {
        item.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
  }, 150)
}

function mastHead({ metaData }) {
  const { state: { style, theme } } = useContext(ThemeContext)

  const { layout } = useSiteMetadata()
  // const { github: { viewer: user } } = useStaticQuery(
  //   graphql`
  //     query {
  //       github {
  //         viewer {
  //           name
  //           email
  //           bio
  //           login
  //           url
  //           avatarUrl
  //           isHireable
  //           location
  //         }
  //       }
  //     }
  //   `
  // )
  const metadataClasses = layout === 'stacked' ? 'd-md-inline-block mr-3' : 'd-flex flex-items-center mb-3'
  const metadataStyles = { justifyContent: 'center' }
  const { info: { name, github, twitter, bio, email, hireable, avatarUrl }, links } = SidebarData

  return (
    <div style={{ textAlign: 'center' }}>
      <Link to='/'>
        <img src={avatarUrl} alt="user-avatar" className="circle mb-3" style={{ maxWidth: '150px' }} width={150} height={150} />
      </Link>
      <h1 className={style === 'dark' ? "text-white" : "mb-2 lh-condensed"}>{name}</h1>
      <p className={`mb-3 f4 ${style === 'dark' ? 'text-white' : 'text-gray'}`}>
        <Emoji text={bio || ''} />
      </p>
      <ul className={`mb-3 f4 ${style === 'dark' ? 'text-white' : 'text-gray'} navLinks`}>
        {links.map(({ title, href }) => (
          <li key={`link-${title}`} onClick={scrollDown}><Link to={href}>{title}</Link></li>
        ))}
      </ul>
      {metaData &&
        <div className="f4 mb-6" style={{ justifyContent: 'center' }}>
          {github &&
            <div className={metadataClasses} style={metadataStyles}>
              <span style={{ color: theme.iconColor }}><Octicon icon={MarkGithub} size={20} verticalAlign='middle' className="mr-2" ariaLabel="GitHub" /></span>
              <OutboundLink href={`https://github.com/${github}`} className={style === 'dark' ? "text-white" : ""}>
                @{github}
              </OutboundLink>
            </div>
          }
          {twitter &&
            <div className={metadataClasses} style={metadataStyles}>
              <span style={{ color: theme.iconColor }}>
                <img src={TWITTER_ICON} width={20} style={{ marginRight: 5 }} />
              </span>
              <OutboundLink href={`https://twitter.com/${twitter}`} className={style === 'dark' ? "text-white" : ""}>
                @{twitter}
              </OutboundLink>
            </div>
          }
          {email &&
            <div className={metadataClasses} style={metadataStyles}>
              <span style={{ color: theme.iconColor }}><Octicon icon={Mail} size={20} verticalAlign='middle' className="mr-2" ariaLabel="email" /></span>
              <OutboundLink href={`mailto:${email}`} className={style === 'dark' ? "text-white" : ""}>
                {email}
              </OutboundLink>
            </div>
          }
          {/* {user.location &&
            <div className={`${metadata_styles} ` + (style === 'dark' && "text-white")}>
              <span style={{ color: theme.iconColor }}><Octicon icon={Location} size={20} verticalAlign='middle' className="mr-2" ariaLabel="Location" /></span>
              {user.location}
            </div>
          } */}
          {hireable &&
            <Link to='/contact' onClick={scrollDown}>
              <span title="Hire me" className="d-inline-block f5 rounded-2 text-white bg-green py-1 px-2">Available for work</span>
            </Link>
          }
        </div>
      }
    </div>
  )
}

export default mastHead
