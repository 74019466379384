import React, { useContext } from "react"

import Layout from "../components/layout"
import { ThemeContext } from "../theme-context"
import MastHead from '../components/mastHead'
import SEO from '../components/seo'
import './sidebarLayout.scss'

function SidebarLayout({ children }) {
  const { state: { style }, dispatch } = useContext(ThemeContext)
  // const { layout } = useSiteMetadata()

  return <Layout>
    <SEO />
    <div className={`d-md-flex ${style !== 'dark' && 'border-md-bottom'}`} style={{minHeight: '100vh'}}>
        <div className={`flex-self-stretch ${style === 'dark' ? 'bg-gray-dark' : 'border-md-right border-gray-light bg-white'} col-md-5 col-lg-4 col-xl-3 px-4 px-md-6 px-lg-7 py-6`}>
            <MastHead metaData={true} />
        </div>
        <div className="col-md-7 col-lg-8 col-xl-9 px-4 py-6 px-lg-7 border-top border-md-top-0 contentBox" style={{ backgroundColor: style === 'dark' ? "#2f363d" : "#fafbfc" }}>
            <div className="mx-auto" style={{ maxWidth: '900px' }} id='child-box'>
                {children}
            </div>
        </div>
    </div>
  </Layout>
}

export default SidebarLayout
