import React, { useContext } from "react"

import SidebarLayout from '../components/sidebarLayout'
import { ThemeContext } from "../theme-context"

function NotFound() {
  const { state: { style }, dispatch } = useContext(ThemeContext)
  return <SidebarLayout>
    <div style={{ textAlign: 'center', fontSize: '24px', margin: '150px 0' }}>
        Whoops, not found...
    </div>
  </SidebarLayout>
}

export default NotFound
